import { WebAppInterface } from "src/app/interfaces/AndroidNative";
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
};

let urlValue: string;
let testing: string;
let cacheURL: string;
let webSocketUrl: string;
let adminSocketUrl: string;
let fmsUrl: string;

export let storageVariable = {
  upDatedJsonData: undefined,
  dashboardData: undefined,
};

export let adminLocalStorage = {
  dealerLoginData: undefined,
  reportData: undefined,
};

let ticketUrl: string;
const applicationPackNames = {
  //"GE":"com.eit.gelement",
  GE: "com.ge.goldenelement.avl",
  APM: "com.eit.apmkingstrack",
  tracalogic: "com.eittracalogic.tracalogic",
  "Move and track": "com.mvt.apmkt",
  "Move and track ios": "com.apm.mvtkt",
  "Upcot-mvt": "com.upcotmvt.upcot",
  Remoncloud: "com.elint.remon",
  Armoron: "com.apmkingstrack.armoron",
};

// let packageName = applicationPackNames.moveandtrack;

let loginImgUrl: string;
let appName = "MnT Live";
//let appName = "Armoron";
// let appName = "MnT test";
//  let armoronappVersion = '2.1.1';
let appVersion = "2.1.3";
// let appVersion = '2.1.9';
// let androidappVersion = '2.1.3';
export const host = {
  apmKT: "armorondealer.apmkingstrack.com",

  staticIp: "13.202.193.120",
  localIp: "13.202.193.120",
  GeLive: "track.thegoldenelement.com",
  testingServer: "3.6.171.231",

  windowServer: "dealertesting.apmkingstrack.com",

  localHost: "192.168.0.120",
  Armoron: "armoron.apmkingstrack.com",
  GE: "mobs.thegoldenelement.com",
  GE_QA: "gps.thegoldenelement.com",
  QA: "34.232.238.80:8090",
  SKT: "armoronapi.apmkingstrack.com:8080",
  BeanStalk: "us-east-1.elasticbeanstalk.com",
  lnt: "lnt.tracalogic.co",
  demo: "75.119.153.123",
};
// http://161.97.151.243:8090/mtrack/#/reports/null
// declare var Android : WebAppInterface;

// if (/(android|iPhone|iPad|iPod)/i.test(navigator.userAgent)) {
//  // packageName = Android.getAppId();
//   packageName = applicationPackNames.moveandtrack;
// } else {
//   urlValue = '/Web';
//   cacheURL = '/Admin';
//   packageName = applicationPackNames.moveandtrack;
// }
let entryPoint;
console.log("hi");
let backgroundImage =
  "https://kingstrackimages.s3.amazonaws.com/loginimages/track_apmkingstrack_com_background.jpg";
switch (appName) {
  case "WFT":
    entryPoint = "WFT";
    break;
  case "Tracalogic":
    urlValue = "http://" + host.lnt + ":8090/Web";
    //urlValue = "http://10.10.10.6:8080";
    //  cacheURL = "http://" + host.lnt
    webSocketUrl = "ws://" + host.lnt + ":8090";
    adminSocketUrl = "ws://" + host.lnt;
    entryPoint = "VTS";
    loginImgUrl = "assets/loginLogo/tracalogic.png";
    ticketUrl = "https://apmkingstrack.freshdesk.com";
    break;

  case "Move and track":
    urlValue = "http://" + host.apmKT + ":8090/Web";
    cacheURL = "http://" + host.apmKT + ":8090/Admin";
    webSocketUrl = "ws://" + host.apmKT + ":8090";
    adminSocketUrl = "ws://" + host.apmKT + ":8090";
    entryPoint = "VTS";
    loginImgUrl = "assets/loginLogo/moveandtrack.png";
    if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent))
      applicationPackNames[appName] = "com.apm.mvtkt";
    ticketUrl = "https://apmkingstrack.freshdesk.com";
    break;

  case "MnT test":
    if (location.protocol == "https:") {
      testing = "https://";
    } else {
      testing = "http://";
    }
    //############################# for mvt user####################
    // urlValue = testing + host.windowServer + "/fleettracking";

    //#############################for mvt dealer#########################################
    urlValue = testing + host.windowServer + "/dealer";

    //urlValue = "http://"+host.localIp;
    cacheURL = testing + host.windowServer + "/Admin";
    webSocketUrl = "wss://" + host.windowServer;
    //webSocketUrl = host.localIp;
    adminSocketUrl = "wss://" + host.windowServer;
    entryPoint = "VTS";
    appName = "Move and track";
    loginImgUrl = "assets/loginLogo/moveandtrack.png";
    if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent))
      applicationPackNames[appName] = "com.apm.mvtkt";
    ticketUrl = "https://apmkingstrack.freshdesk.com";
    fmsUrl = testing + host.windowServer + "/fleettracking";
    break;
  case "MnT Live":
    // #Vijay
    // urlValue = "http://" + "192.168.0.93:8081" + "/fleettracking";
    // #Tariq Bhai
    // urlValue = "http://" + "192.168.0.121:8081" + "/fleettracking";

    //####################################################for mvt user#########################################
    // urlValue = "https://" + host.apmKT + "/fleettracking";
    // urlValue = "http://" + "192.168.31.85:8081" + "/fleettracking";
    //##################################################for mvt dealer##########################################
    urlValue = "https://" + host.apmKT + "/dealer";
    //######################################################################################################

    //urlValue = "http://"+host.localIp;
    cacheURL = "https://" + host.apmKT + "/Admin";
    webSocketUrl = "wss://" + host.apmKT;
    // webSocketUrl = "ws://" + "192.168.31.85:8091";
    //webSocketUrl = host.localIp;
    adminSocketUrl = "wss://" + host.apmKT;
    // adminSocketUrl = "ws://" + "192.168.31.85:8099";
    entryPoint = "VTS";
    appName = "Move and track";
    loginImgUrl = "assets/loginLogo/moveandtrack.png";
    if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent))
      applicationPackNames[appName] = "com.apm.mvtkt";
    ticketUrl = "https://apmkingstrack.freshdesk.com";
    fmsUrl = "http://" + host.apmKT + ":8095/fms";
    break;

  default:
    urlValue = "http://" + host.apmKT + ":8090/Web";
    cacheURL = "http://" + host.apmKT + "/fleettrackingadmin";
    entryPoint = "VTS";
}

export let serverUrl = {
  web: urlValue,
  Admin: cacheURL,
  websocket: webSocketUrl,
  adminSocket: adminSocketUrl,
  ticketUrl: ticketUrl,
  fmsUrl: fmsUrl,
};
export const app = {
  package: applicationPackNames[appName],
  entryPoint: entryPoint,
  loginImgUrl: loginImgUrl,
  appName: appName,
  appVersion: appVersion,
  bgImage: backgroundImage,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
